// Admin pages
const ABM_Sucursales = () => import(/* webpackChunkName: "common" */ '@/pages/ABM_Sucursales.vue')
const miNegocio = () => import(/* webpackChunkName: "common" */'@/pages/miNegocio.vue')
const notificarPago = () => import(/* webpackChunkName: "common" */'@/pages/notificarPago.vue')
const programaMusica = () => import(/* webpackChunkName: "common" */ '@/pages/Radios/programaMusica.vue')
const reporteControlAire = () => import(/* webpackChunkName: "common" */ '@/pages/ControlAire/reporte.vue')
const certificadoSpot = () => import(/* webpackChunkName: "common" */ '@/pages/ControlAire/reporte.vue')
const bibliotecaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/bibliotecaSpot.vue')
const programaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/programaSpot.vue')
const resumenSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/resumenSpot.vue')
const altaSucursal = () => import(/* webpackChunkName: "common" */ '@/pages/Sucursales/altaSucursal.vue')
const altaSucursalDatos = () => import(/* webpackChunkName: "common" */ '@/pages/Sucursales/altaSucursalDatos.vue')
const sucursalProgramacion = () => import(/* webpackChunkName: "common" */ '@/pages/Sucursales/sucursalProgramacion.vue')
const altaProgramacionSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/altaProgramacion.vue')
const altaProgramacionMusica = () => import(/* webpackChunkName: "common" */ '@/pages/Radios/altaProgramacion.vue')
const administrarProgramacionMusica = () => import(/* webpackChunkName: "common" */ '@/pages/Radios/administrarProgramacion.vue')
const misFacturas = () => import(/* webpackChunkName: "common" */ '@/pages/Facturas/FacturaCliente/FacturaCliente.vue')
const altaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/altaSpot.vue')
const pedidoSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/pedidoSpot.vue')
const altaPedidoSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/altaPedidoSpot.vue')
const administrarProgramacionSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/administrarProgramacion.vue')
const controldeaire = () => import(/* webpackChunkName: "common" */ '@/pages/Radios/controlAire.vue')


const CliRoutes = [
  {
    path: 'sucursales',
    name: 'sucursales',
    component: ABM_Sucursales

  },
  {
    path: 'altaSucursal',
    name: 'Creá una nueva sucursal',
    component: altaSucursal
  },
  {
    path: 'altaSucursalDatos',
    name: 'Datos de la sucursal',
    component: altaSucursalDatos,
    props: true
  },
  {
    path: 'sucursalProgramacion',
    name: 'Programaciones',
    component: sucursalProgramacion,
    props: true
  },
  {
    path: 'programaMusica',
    name: 'Mi música',
    component: programaMusica
  },
  {
    path: 'altaProgramacionRadio',
    name: 'Nueva programación de música',
    component: altaProgramacionMusica
  },
  {
    path: 'administrarProgramacionMusica',
    name: 'Programaciones de música',
    component: administrarProgramacionMusica
  },
  {
    path: 'bibliotecaSpot',
    name: 'Mis spots',
    component: bibliotecaSpot
  },
  {
    path: 'programaSpot',
    name: 'Mis programaciones de spots',
    component: programaSpot
  },
  {
    path: 'resumenSpot',
    name: 'Resumen',
    component: resumenSpot
  },
  {
    path: 'altaSpot',
    name: 'Cargá tu spot',
    component: altaSpot
  },
  {
    path: 'pedidoSpot',
    name: 'Pedido de spot',
    component: pedidoSpot
  },
  {
    path: 'altaPedidoSpot',
    name: 'Generación voz off',
    component: altaPedidoSpot
  },
  {
    path: 'altaprogramacionSpot',
    name: 'Nueva programación de spot',
    component: altaProgramacionSpot
  },
  {
    path: 'certificadoSpot',
    name: 'Certificado Spots Mensual',
    component: certificadoSpot
  },
  {
    path: 'reporteControlAire',
    name: 'Control Aire',
    component: reporteControlAire
  },
  {
    path: 'administrarProgramacionSpot',
    name: 'Programaciones de spot',
    component: administrarProgramacionSpot
  },
  
  {
    path: 'controldeaire/:tipo',
    name: 'Control de aire',
    component: controldeaire
  },
  {
    path: 'miNegocio',
    name: 'mi negocio',
    component: miNegocio
  },
  {
    path: 'notificarPago',
    name: 'Notificar un pago',
    component: notificarPago
  },
  {
    path: 'misfacturas',
    name: 'Facturas',
    component: misFacturas
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default CliRoutes
