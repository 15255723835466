<template>
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebarCliente.dashboard')" icon="tim-icons icon-chart-pie-36" />
        <sidebar-link to="/sucursales" :name="$t('sidebarCliente.sucursales')" icon="tim-icons icon-bank" />
        <sidebar-link to="/programaMusica" :name="$t('sidebarCliente.adminMusica')" icon="tim-icons icon-sound-wave" />
        <li to="/" tag="li" class="">
          <a data-toggle="collapse"
             @click="toggle('controlAire')"
             class="sidebar-menu-item">
            <i class="tim-icons icon-image-02"></i>
            <p>Control de aire<b class="caret"></b></p>
          </a>
          <div class="collapse"
          :class="{show:isOpen['controlAire']}">
            <ul class="submenu nav">
              <!-- <sidebar-link to="/controldeaire/1" :name="$t('Musica')" icon="" /> -->
              <sidebar-link to="/controldeaire/2" :name="$t('Spots')" icon="" />
              <!-- <sidebar-link to="/dropbox" :name="$t('Dropbox')" icon="" /> -->
              <sidebar-link to="/certificadoSpot" :name="$t('sidebarCliente.certificadoCliente.lista')" icon="" />
            </ul>
          </div>
        </li>
        <li to="/" tag="li" class="">
          <a data-toggle="collapse"
          @click="toggle('spots')"
             class="sidebar-menu-item">
             <i class="tim-icons icon-image-02"></i>
             <p>{{$t('sidebarCliente.adminSpot.titulo')}}<b class="caret"></b></p>
            </a>
            <div class="collapse"
            :class="{show:isOpen['spots']}">
            <ul class="submenu nav">
              <sidebar-link to="/bibliotecaSpot" :name="$t('sidebarCliente.adminSpot.lista')" icon="" />
              <sidebar-link to="/programaSpot" :name="$t('sidebarCliente.adminSpot.programa')" icon="" />
              <sidebar-link to="/pedidoSpot" :name="$t('sidebarCliente.pedidoSpot')" icon="" />
              
            </ul>
          </div>
        </li>
        
        <sidebar-link to="/misfacturas" :name="$t('sidebarCliente.facturaCliente')" icon="tim-icons icon-paper" />
        <!-- <sidebar-link to="/dropbox" :name="$t('Conexión a Dropbox')" icon="tim-icons icon-image-02"/> -->

      </template>
    </side-bar>
</template>

<script>

export default {
  data () {
    return {
      isOpen: {
        controlAire:false,
        spots:false
      }
    }
  },
  methods: {
    toggle (menu) {
      this.isOpen[menu] = !this.isOpen[menu]
      this.$emit('change', this.isOpen[menu])
    }
  },
  mounted () {
    const url = this.$route.path
    // console.log('url:' + url);

    if ((url == '/bibliotecaSpot') || (url == '/programaSpot') || (url == '/pedidoSpot') ||
      (url == '/altaSpot') || (url == '/altaprogramacionSpot') || (url == '/administrarProgramacionSpot') ||
      (url == '/resumenSpot')) {
      this.isOpen['spots'] = true
    }
    if ((url == '/controldeaire/1') || (url == '/controldeaire/2') || (url == '/certificadoSpot')) {
      this.isOpen['controlAire'] = true
    }
    // let dropBox = document.createElement("script");
    // dropBox.setAttribute(
    //   "src",
    //   "https://www.dropbox.com/static/api/2/dropins.js"
    // );
    // dropBox.setAttribute("id", "dropboxjs");
    // dropBox.setAttribute("data-app-key", "ackgvivbqmjb3bp");
    // document.head.appendChild(dropBox);
  }
}

</script>
