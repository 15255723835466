// Paginas admitidas para una sucursal
const pedidoSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Operador/Spots/pedidoSpot.vue')
const noticias = () => import(/* webpackChunkName: "common" */ '@/pages/Operador/Spots/bibliotecaNoti.vue')

const altaNoticias = () => import(/* webpackChunkName: "common" */ '@/pages/Operador/Spots/altaNoti.vue')
const tablaPedidos = () => import(/* webpackChunkName: "common" */ '@/pages/Operador/Spots/tablaPedidos.vue')
const subirSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Operador/Spots/subirSpot.vue')
const OpeRoutes = [
  {
    path: 'noticias',
    name: 'Noticias',
    component: noticias
  },
  {
    path: 'altaNoti',
    name: 'Alta de noticias',
    component: altaNoticias
  },
  {
    path: 'pedidoSpot',
    name: 'Pedido de spot',
    component: pedidoSpot
  },
  {
    path: 'pedidos',
    name: 'Pedidos de clientes',
    component: tablaPedidos
  },
  {
    path: 'nuevoSpot',
    name: 'Cargar un spot',
    component: subirSpot
  }

]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default OpeRoutes
