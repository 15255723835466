<template>
  <footer class="footer">
    <div class="container-fluid">
      <!-- <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="#">
            Example
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            Example1
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            Example2
          </a>
        </li>
      </ul> -->
      <div class="copyright">
        {{texto_info_base}} || ©ClubF5 {{year}}.
      </div>
    </div>
  </footer>
</template>
<script>
import InfoService from '../../services/infoServices'
import reproductor from '../../components/Reproductor'
export default {
  components: {
    reproductor
  },
  data () {
    return {
      year: new Date().getFullYear(),
      texto_info_base: 'Administracion ClubF5'
    }
  },
  methods: {
    getInfoBase () {
      InfoService.getBase()
        .then(res => {
          this.texto_info_base = res
        })
    }
  },
  mounted () {
    //this.getInfoBase()
  }
}
</script>
<style>
</style>
