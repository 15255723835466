// Paginas admitidas para una sucursal
const bibliotecaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/bibliotecaSpot.vue')
const programaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/programaSpot.vue')
const resumenSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/resumenSpot.vue')
const administrarProgramacionMusica = () => import(/* webpackChunkName: "common" */ '@/pages/Radios/administrarProgramacion.vue')
const altaSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/altaSpot.vue')
const administrarProgramacionSpot = () => import(/* webpackChunkName: "common" */ '@/pages/Spots/administrarProgramacion.vue')
const RepRoutes = [
  {
    path: 'administrarProgramacionMusica',
    name: 'Programaciones de música',
    component: administrarProgramacionMusica
  },
  {
    path: 'bibliotecaSpot',
    name: 'Mis spots',
    component: bibliotecaSpot
  },
  {
    path: 'programaSpot',
    name: 'Mis programaciones de spots',
    component: programaSpot
  },
  {
    path: 'resumenSpot',
    name: 'Resumen',
    component: resumenSpot
  },
  {
    path: 'altaSpot',
    name: 'Cargá tu spot',
    component: altaSpot
  },
  {
    path: 'administrarProgramacionSpot',
    name: 'Programaciones de spot',
    component: administrarProgramacionSpot
  }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default RepRoutes
