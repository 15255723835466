<template>
  <fade-transition>
    <div class="container fixed-bottom">
      <div class="row row-centered pos" id="">
        <div class="col-12">
          <div v-if="visible" class="alert" :class="[`alert-${type}`, { 'alert-with-icon': withIcon }]" role="alert">
            <div class="row">
              <slot v-if="!dismissible"></slot>
                <div v-else class="col-2 container">

                    <slot>
                      <img class ="img-playlist" :src="urlImgPlaylist">
                    </slot>
                  </div>
                  <div class="col-8">
                    <slot >
                      <audio :src="urlMediaPlaylist" controls style="width: 100%;" autoplay controlsList="nodownload" preload="auto"></audio>
                    </slot>
                  </div>
                  <div class="col-2">
                    <slot name="dismiss-icon">
                      <button type="button" class="close" aria-label="Close" @click="dismissAlert">
                              <span aria-hidden="true">
                                <i class="tim-icons icon-simple-remove"></i>
                              </span>
                      </button>
                    </slot>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fade-transition>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'

export default {
  name: 'reproductor-demo',
  components: {
    FadeTransition
  },
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Alert type'
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: 'Whether alert is dismissible (closeable)'
    },
    withIcon: {
      type: Boolean,
      default: false,
      description: 'Whether alert contains icon'
    },
    urlImgPlaylist: {
      type: String,
      default: '',
      description: 'url de la imagen'
    },
    urlMediaPlaylist: {
      type: String,
      default: '',
      description: 'url del demo'
    }
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    dismissAlert () {
      this.visible = false
      this.$store.state.playDemo = false // Pongo en falso para que no se muestre el reproductor
      this.$store.state.urlDemo = '', // vacío el src del tag audio para limpiar
      this.$store.state.urlImg = '' // vacío la url de la imagen
    }
  }
}
</script>
