import VueRouter from 'vue-router'
import CliRoutes from './clienteRoutes'
import RepRoutes from './reproductorRoutes'
import OpeRoutes from './operadorRoutes'
import userservice from '../services/UserServices'

// GeneralViews
import NotFound from '@/pages/NotFoundPage.vue'
import LoginLayout from '@/layout/Login/LoginLayout.vue'

// dependientes del usuario
import DashboardLayoutCliente from '@/layout/cliente/ClienteLayout.vue'
import DashboardLayoutReproductor from '@/layout/reproductor/ReproductorLayout.vue'
import DashboardLayoutOperador from '@/layout/operador/OperadorLayout.vue'
const Login = () => import(/* webpackChunkName: "dashboard" */'@/pages/Login.vue')

var Layout = null
let RoleUser = null
var Dashboard = () => (1)
if (!userservice.autenticado()) {
  Layout = LoginLayout
} else {
  RoleUser = (userservice.current().role)
  switch (RoleUser) {
    case 'Cliente':
      Layout = DashboardLayoutCliente
      Dashboard = () => import(/* webpackChunkName: "dashboard" */'@/pages/Dashboard.vue')
      break
    case 'Reproductor':
      Layout = DashboardLayoutReproductor
      Dashboard = () => import(/* webpackChunkName: "dashboard" */'@/pages/DashboardNoCliente.vue')
      break
    case 'Operador':
      Dashboard = () => import(/* webpackChunkName: "dashboard" */'@/pages/DashboardNoCliente.vue')
      Layout = DashboardLayoutOperador
      break
    default:
      Layout = LoginLayout
  }
}



var routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Inicio',
        component: Dashboard
      },
      {
        path: 'login',
        name: 'Iniciar Sesión',
        component: Login,
        meta: { Publico: true }
      }
    ]
  },
  { path: '*', component: NotFound }
]

if (RoleUser === 'Cliente') {
  routes[0].children = routes[0].children.concat(CliRoutes)
}

if (RoleUser === 'Reproductor') {
  routes[0].children = routes[0].children.concat(RepRoutes)
}

if (RoleUser === 'Operador') {
  routes[0].children = routes[0].children.concat(OpeRoutes)
}
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.Publico && !userservice.autenticado()) {
    return next({ name: 'Iniciar Sesión' })
  } else if (to.meta.Publico && userservice.autenticado()) {
    return next({ name: 'Inicio' })
  }
  return next()
})

export default router
