import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterPrefetch from 'vue-router-prefetch'
import App from './App'
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from './router/index'
import store from './store/index' // VUEX

import BlackDashboard from './plugins/blackDashboard'
import i18n from './i18n'
import './registerServiceWorker'
import VueConfirmDialog from 'vue-confirm-dialog'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import UUID from 'vue-uuid'
import VueTimepicker from 'vue2-timepicker'
import * as signalR from '@microsoft/signalr' 

Vue.use(UUID)
Vue.use(moment)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(signalR)
Vue.use(BlackDashboard)
Vue.use(VueRouter)
Vue.use(RouterPrefetch)
Vue.use(VueTimepicker)

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
