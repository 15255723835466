<template>
  <div class="wrapper">

    <menu-view></menu-view>

    <div class="main-panel">

      <top-navbar></top-navbar>

      <cliente-content @click.native="toggleSidebar">

      </cliente-content>
      <div class="footer" v-if="$store.getters.getEstadoPlayDemo">

          <reproductor
            type="default"
            dismissible
            :urlImgPlaylist="$store.getters.getUrlImg"
            :urlMediaPlaylist="$store.getters.getUrlMedia">

          </reproductor>

      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import ClienteContent from './Content.vue'
import MobileMenu from './MobileMenu'
import MenuView from './MenuView'
import Reproductor from '../../components/Reproductor'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    ClienteContent,
    MobileMenu,
    MenuView,
    Reproductor
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}
</script>
