<template>
  <nav id="nav-top" class="navbar navbar-expand-lg navbar-absolute">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#">{{routeName}}</a>
      </div>
      <button class="navbar-toggler" type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div v-if="cantNotis > 0" class="notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-chat-33"></i>
                <p class="d-lg-none">
                  Notificaciones
                </p>
              </a>
              <li v-for="notificacion in data.notis" class="nav-link" :key="notificacion.ps3_codigo">

                <a href="#" @click="verNotificacion()" class="nav-item dropdown-item">
                  {{notificacion.ps3_mensaje}}
                  <!-- <span>{{moment(notificacion.ps3_fechaAlta).format('DD/MM/YYYY hh:mm')}}</span> -->
                  <span>{{notificacion.ps3_fechaAlta}}</span>
                </a>

              </li>
            </base-dropdown>

            <base-dropdown tag="li"
                           :menu-on-right="!$rtl.isRTL"
                           title-tag="a"
                           class="nav-item"
                           menu-classes="dropdown-navbar">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <!-- <div class="photo">
                  <img src="img/anime3.png">
                </div> -->
                <i class="tim-icons icon-single-02"></i>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">
                  Opciones
                </p>
              </a>
              <li >
                <button v-on:click="Logout" class="nav-item dropdown-item">Cerrar sesión</button>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'
import Modal from '@/components/Modal'
import UserServices from '../../services/UserServices'
import PedidoSpotService from '../../services/PedidoSpotServices'
import moment from 'moment'

export default {
  components: {
    CollapseTransition,
    Modal
  },
  computed: {
    routeName () {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
    isRTL () {
      return this.$rtl.isRTL
    }
  },
  data () {
    return {
      activeNotifications: false,
      showMenu: false,
      data: {
        notis: []
      },
      cantNotis: null
    }
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    Logout () {
      UserServices.logout().then(res => {
        window.location.href = ''
      })
    },
    loadNotificaciones () {
      const notis = []
      PedidoSpotService.GetNotificacionesNoLeidas()
        .then(res => {
          this.cantNotis = res.length
          if (res.length > 0) {
            res.forEach(element => {
              /*
                * armo un json en base al response.
                */
              notis.push({
                ps3_codigo: element.ps3_codigo,
                ps3_mensaje: element.ps3_mensaje,
                ps3_leido: element.ps3_leido,
                ps3_fechaAlta: moment(element.ps3_fechaAlta).format('DD-MM-YYYY'),
                ps3_codigoPs0: element.ps3_codigoPs0
              })
            })
          } else {
            notis.push({
              ps3_codigo: 0,
              ps3_mensaje: 'No tenés mensajes para leer'
            })
          }

          // finalmente asigno al array de rows, el arreglo json armado anteriormente
          this.data.notis = notis
        })
    },
    verNotificacion () {
      this.$router.push({
        path: 'pedidoSpot'
      })
    }
  },
  mounted () {
    this.loadNotificaciones()
  }
}
</script>
<style>
</style>
