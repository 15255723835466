import SideBar from '@/components/SidebarPlugin'
import Notify from '@/components/NotificationPlugin'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import RTLPlugin from './RTLPlugin'

// css assets
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/sass/black-dashboard.scss'
import '@/assets/css/nucleo-icons.css'
import '@/assets/css/custom.css'
export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(Notify)
    Vue.use(RTLPlugin)
  }
}
