<template>
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebarCliente.dashboard')" icon="tim-icons icon-chart-pie-36"/>

        <li to="/" tag="li" class="">
          <a data-toggle="collapse"
              @click="toggle"
              class="sidebar-menu-item">
            <i class="tim-icons icon-image-02"></i>
            <p>{{$t('sidebarCliente.adminSpot.titulo')}}<b class="caret"></b></p>
          </a>
          <div class="collapse"
              :class="{show:isOpen}">
            <ul class="submenu nav">
              <sidebar-link to="/pedidoSpot" :name="$t('sidebarCliente.pedidoSpot')" icon=""/>
              
              <sidebar-link to="/noticias" :name="$t('Alta de noticias')" icon=""/>
            </ul>
          </div>
        </li>

      </template>
    </side-bar>
</template>

<script>

export default {
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
      this.$emit('change', this.isOpen)
    },
  },
  mounted () {
    const url = this.$route.path
    
    if ((url == '/bibliotecaSpot') || (url == '/programaSpot') || (url == '/pedidoSpot') ||
      (url == '/altaSpot') || (url == '/altaprogramacionSpot') || (url == '/administrarProgramacionSpot') ||
      (url == '/resumenSpot')) {
      this.isOpen = true
    }
    
  }
}

</script>
