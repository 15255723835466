import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cliente: null,
    limiteSucursal: true,
    playDemo: false,
    urlDemo: '',
    urlImg: ''
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    getCliente (state) {
      return state.cliente
    },
    getLimiteSucursal (state) {
      return state.limiteSucursal
    },
    getEstadoPlayDemo (state) {
      return state.playDemo
    },
    getUrlImg (state) {
      return state.urlImg
    },
    getUrlMedia (state) {
      return state.urlDemo
    }
  },
  modules: {
  }
})
