<template>
  <div class="wrapper">

    <div class="main-panel">

      <top-navbar></top-navbar>

      <login-content>

      </login-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import LoginContent from './Content.vue'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    LoginContent
  }
}
</script>
